<template>
  <div class="login_container">
    <div class="login_box">
      <!-- 头像区域 -->
      <div class="avatar_box">
        <img src="https://cube.elemecdn.com/0/88/03b0d39583f48206768a7534e55bcpng.png" alt="">
      </div>
      <!-- 登录表单区域 -->
      <el-form ref="loginFormRef" :rules="loginFromRules" :model="loginForm" label-width="0px" class="login_from">
        <!-- 用户名 -->
        <el-form-item prop="username">
          <el-input v-model="loginForm.username" prefix-icon="el-icon-user" placeholder="请输入用户名" />
        </el-form-item>
        <!-- 密码 -->
        <el-form-item prop="password">
          <el-input v-model="loginForm.password" prefix-icon="el-icon-lock" type="password" placeholder="请输入密码"/>
        </el-form-item>
        <!-- 按钮区域 -->
        <el-form-item class="btns">
          <el-button type="primary" @click="login">登录</el-button>
          <el-button type="info" @click="resetLoginForm">重置</el-button>
        </el-form-item>
      </el-form>
    </div>
  </div>
</template>
<script>

import { post,headersToken} from "../network/api";
import { setToken } from "../utils/auth"
export default {
  data() {
    return {
      // 这是登录表单的数据绑定对象
      loginForm: {
        username: '',
        password: ''
      },
      // 这是表单的验证规则对象
      loginFromRules: {
        // 验证用户名是否合法
        username: [
          { required: true, message: '请输入登录号码', trigger: 'blur' },
          { min: 11, max: 11, message: '请输入正确格式的号码', trigger: 'blur' }
        ],
        // 验证密码是否合法
        password: [
          { required: true, message: '请输入登录密码', trigger: 'blur' },
          { min: 6, max: 16, message: '长度在 6 到 16 个字符之间', trigger: 'blur' }
        ]
      }
    }
  },
  methods: {
    // 点击重置按钮，重置登录表单,处理函数
    resetLoginForm() {
      this.$refs.loginFormRef.resetFields()
    },
    login() {
      const that = this
      if(that.loginForm.username != "" && that.loginForm.password !="")
      {
        post('/login',{
          "userName":that.loginForm.username,
          "password":that.loginForm.password
        }).then(res=>{
          console.log(res)
          if(res.data.status == 200)
          {
            setToken(res.data.data)
            console.log(setToken(res.data.data));
            this.$message({
                message: '登录成功',
                type: 'success'
              });
            //将token设置为请求头
            headersToken()
            
            setTimeout(()=>{
              that.$router.push('/home')
            },500)
          }
          else
          {
            console.log(123)
            this.$message.error('亲,请检查检查账号或者密码是否输对了哟');
          }
        }).catch(err=>{
          console.log(err)
        })
      }
      else
      {
        this.$message.error('电话或密码不能为空');
      }
    }
  }
}
</script>
<style lang="less" scoped>
.login_container{
    // background-color:lightseagreen;
    background-image: url('../assets/bg1.jpg');
    opacity: 0.8;
    height: 100%;
}
.login_box{
    width: 450px;
    height: 300px;
    background-color: white;
    border-radius: 3px;
    position:absolute;
    left: 50%;
    top: 50%;
    transform: translate(-50%, -50%);
    .avatar_box{
      height: 130px;
      width: 130px;
      border: 1px solid #eee;
      border-radius: 50%;
      padding: 10px;
      box-shadow: 0 0 10px #ddd;
      position: absolute;
      left: 50%;
      transform: translate(-50%, -50%);
      background-color:white;
      img{
          widows: 100%;
          height: 100%;
          border-radius: 50%;
          background-color: #eee;
      }
    }
}
.btns{
    display: flex;
    justify-content: flex-end;
}
.login_from{
    position: absolute;
    bottom: 0;
    width: 100%;
    padding: 0 20px;
    box-sizing: border-box;
}
</style>
